import { enableProdMode, ErrorHandler, importProvidersFrom, LOCALE_ID } from '@angular/core';
import es from '@angular/common/locales/es';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/00-app/app.component';
import { PreloadAllModules, provideRouter, RouteReuseStrategy, withPreloading } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { routes } from './app/00-app/app-routing.module';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TokenInterceptor } from './app/05-shared/interceptors/token.interceptor';
import { OfflineInterceptor } from './app/05-shared/interceptors/offline.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage-angular';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import Bugsnag from '@bugsnag/js'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import 'hammerjs';
defineCustomElements(window);

if (environment.production) {
  enableProdMode();
}

registerLocaleData(es);

// configure Bugsnag ASAP
Bugsnag.start({ apiKey: '97922316a2bc008c96811b9a9e520ed1' })
BugsnagPerformance.start({ apiKey: '97922316a2bc008c96811b9a9e520ed1' })

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular({
      innerHTMLTemplatesEnabled: true,
    }),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass:OfflineInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }).providers,
    importProvidersFrom(IonicStorageModule.forRoot()),
    
  ],
  
});