import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, timeout } from 'rxjs';
import { API_URL, VERSION } from 'src/environments/environment';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class ServerPingService {
  httpClient = inject(HttpClient);
  statePing = new BehaviorSubject<boolean>(false);
  private networkService = inject(NetworkService);

  constructor() {
    this.checkPing().then();
  }

  /**
   * interval para comprobar el estado del servidor
   * @returns 
   */
  async checkPing() {
    /*const interval = 5000;
    this.ping().then(() => {
      this.statePing.next(true);
      //this.networkService.setOfflineMode(false);
    }).catch(() => {
      this.statePing.next(false);
      //this.networkService.setOfflineMode(true);
    });
    setInterval(() => {
      this.ping().then(() => {
        this.statePing.next(true);
        //this.networkService.setOfflineMode(false);
      }).catch(() => {
        this.statePing.next(false);
        //this.networkService.setOfflineMode(true);
      });
    }, interval);*/
  }


  async ping() {
    const url = `${API_URL}/${VERSION}/ping`;
    return lastValueFrom(this.httpClient.get(url).pipe(
      timeout(1000)
    ));
  }

  getStatePing(): Observable<boolean> {
    return this.statePing;
  }


}
